import React, { useEffect, useState, } from 'react';
import {
    Box, Button, Grid, Card, CardContent, FormControl,
    Divider, Typography, TextField, Container, LinearProgress,
    Radio, RadioGroup, FormControlLabel, InputAdornment, FormHelperText, IconButton, Switch,
    FormGroup,
    Alert
} from '@mui/material';
import * as Api from "../../../../Container/config/Apis";
import config from '../../../../Container/config';
import { useSnackbar } from 'notistack';
import _ from "underscore";
import { useSelector } from 'react-redux';
import DialogAlert from '../../../common/Alerts/Dialog';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DoneIcon from '@mui/icons-material/Done';
import NoResult from '../../../common/NoResult';
import discountIcon from '../../../../Assets/images/no-result/api-key.svg';

const apiList = [
    { id: 1, text: '/v1/products/list' },
    { id: 2, text: '/v1/products/[product_id]>' },
    { id: 3, text: '/v1/order' },
    { id: 4, text: '/v1/order/[order_id]>' },
    { id: 5, text: '/v1/page' },
    { id: 6, text: '/v1/page/[page_id]>' },
    { id: 7, text: '/v1/store' }
]

const PublicAPI = () => {
    const dataVal = useSelector((state) => state.dataValue)
    const { enqueueSnackbar } = useSnackbar();
    const snack = (msg, variant) => {
        enqueueSnackbar(msg, { variant });
    }
    const [apiKey, setApiKey] = useState('')
    const [loadingBar, setloadingBar] = useState(true)
    const [showDialog, setOpen] = React.useState(false);
    const [content, setContent] = React.useState({
        title: "Are you sure?",
        message: ""
    });
    const [copied, setcopied] = useState(false)
    const [copiedApi, setCopiedApi] = useState("")

    const closeDialog = () => {
        setOpen(false);
        setContent({
            ...content,
            message: ""
        })
    }

    const getApiKey = () => {
        Api.GetApi(config.PUBLIC_KEY).then(res => {
            setloadingBar(false)
            console.log("res shipping: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                if (res.data.data && res.data.data.public_api_token) {
                    setApiKey(res.data.data.public_api_token)
                    closeDialog()
                }
            }
        })
    }
    const copiedFn = () => {
        setcopied(true)
        setTimeout(() => {
            setcopied(false)
        }, 2000);
    }
    const generateKey = () => {
        if (apiKey) {
            setOpen(true);
            setContent({
                ...content,
                message: "You want to generate new public API key."
            })
        } else {
            generateApiKey()
        }
    }

    const generateApiKey = () => {
        Api.GetApi(config.PUBLIC_KEY_GENERATE).then(res => {
            console.log("res shipping: ", res)
            if (res.error === true) {
                snack(res.response.data.error, 'error');
            } else {
                if (res.data.data) {
                    getApiKey()
                }
            }
        })

    }
    const confirmDialog = () => {
        generateApiKey()
    }

    const copyFn = (data) => {
        setCopiedApi(data.id)
        setTimeout(() => {
            setCopiedApi("")
        }, 2000);
    }

    useEffect(() => {
        getApiKey()
    }, []);

    return (
        <>
            {loadingBar ?
                <LinearProgress className="top_loader" color="info" sx={{ mt: "-25px", mb: "21px" }} />
                :
                null
            }
            <Container maxWidth="lg">
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={6}>
                            <Typography variant="h5" component="div"> Public API key</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: { sm: 'right' }, flexShrink: { sm: 0 } }}>
                            <Button variant='contained' onClick={generateKey}>Generate {apiKey ? "new" : ""} API key</Button>
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Card sx={{ mb: 2 }}>
                        <CardContent>
                            <Box>
                                <Grid container spacing={2} columns={12}>
                                    <Grid item md={12}>
                                        <Typography variant="p" component="div" sx={{ mb: 0 }} gutterBottom>Get smooth access to your Shopyxa store data using our public API key.<br /> Do not share the key with unauthorized persons to maintain the security of your data. <a href='#' target='_blank' className='link'>Learn more</a></Typography>
                                    </Grid>
                                </Grid>
                                {apiKey ?
                                    <Box sx={{ mt: 2 }}>
                                        <Alert
                                            severity="success"
                                            icon={false}
                                            action={
                                                <Button color="inherit" size="small" className='noHover'>
                                                    <CopyToClipboard text={apiKey} onCopy={copiedFn}>
                                                        {copied ?
                                                            <DoneIcon sx={{ width: "14px !important", height: "auto" }} />
                                                            :
                                                            <ContentCopyOutlinedIcon sx={{ width: "14px !important", height: "auto" }} />
                                                        }
                                                    </CopyToClipboard>
                                                </Button>
                                            }
                                            sx={{ fontSize: 14, fontWeight: 700 }}
                                        >
                                            {apiKey}
                                        </Alert>
                                        <Divider sx={{ mt: 2 }} />
                                        <Box sx={{ mt: 3 }}>
                                            <Box sx={{ mb: 2 }}>
                                                <Typography variant='p' component={"strong"}>Here is the list of public Apis.</Typography>
                                            </Box>
                                            {apiList.map((api, i) => {
                                                return (
                                                    <Alert
                                                        severity="info"
                                                        icon={false}
                                                        sx={{ fontSize: 14, fontWeight: 400, mb: 1 }}
                                                    >
                                                        {`https://api.${Api.appURL}${api.text}`}
                                                        <Button color="inherit" size="small" className='noHover p-0' sx={{ minWidth: "35px" }}>
                                                            <CopyToClipboard text={`https://api.${Api.appURL}${api.text}`} onCopy={() => copyFn(api)}>
                                                                {copiedApi == api.id ?
                                                                    <DoneIcon sx={{ width: "14px !important", height: "auto" }} />
                                                                    :
                                                                    <ContentCopyOutlinedIcon sx={{ width: "14px !important", height: "auto" }} />
                                                                }
                                                            </CopyToClipboard>
                                                        </Button>
                                                    </Alert>
                                                )
                                            })}
                                        </Box>
                                    </Box>
                                    :
                                    <Box sx={{ my: 5, textAlign: "center" }}>
                                        <NoResult
                                            icon={discountIcon}
                                            title={"See the list of your API keys here."}
                                            subtitle={"Access your product and order details using these keys."}
                                            btn={false}
                                            btnText={"Generate API key"}
                                        />
                                    </Box>
                                }
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Container>


            <DialogAlert
                showDialog={showDialog}
                closeDialog={closeDialog}
                content={content}
                confirmDialog={confirmDialog}
                smallContent
            />

        </>
    );
}

export default PublicAPI